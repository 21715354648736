import { HomeArticle } from "components/Homepage/HomeArticle";
import { HomeSection } from "components/Homepage/HomeSection";
import { MainPageWrapper } from "components/Layout/MainPageWrapper.tsx";
import { OptimizedImage } from "components/OptimizedImage";
import type React from "react";
import loginImage from "/images/login-image.png";
import vector from "/images/vector.png";

const ProviderAuthWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainPageWrapper color="gray-100" marginTop="none">
      <HomeSection
        horizontalPadding={"clintDesktopOnly"}
        verticalPadding={"default"}
      >
        <HomeArticle
          horizontalPadding={"none"}
          verticalPadding={"none"}
          maxWidth={"1440"}
        >
          <div className="sticky top-16 flex h-fit w-full flex-row gap-4 flex-wrap">
            <div className="lg:rounded-clint flex w-full lg:basis-[422px] grow flex-col items-center justify-center lg:border lg:border-gray-200 bg-white p-8">
              {children}
            </div>

            <OptimizedImage
              source={loginImage}
              alt="Connectez-vous à votre espace"
              containerClassName="mx-0 w-full h-auto lg:basis-[422px] grow relative flex w-full items-center justify-center"
              className="lg:rounded-clint lg:border lg:border-gray-200"
              maxHeight={792 * 1.5}
              height={792 * 1.5}
              width={672 * 1.5}
              imageProps={{
                minHeight: "100%",
                objectFit: "cover",
              }}
            >
              <div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center">
                <img
                  src={vector}
                  className=""
                  width={530}
                  height={530}
                  alt="cercle GoodCollect"
                />
              </div>
            </OptimizedImage>
          </div>
        </HomeArticle>
      </HomeSection>
    </MainPageWrapper>
  );
};

export default ProviderAuthWrapper;
